frappe.provide("frappe.views");

frappe.views.CustomListSidebar = class CustomListSidebar extends frappe.views.ListSidebar {
	constructor(opts) {
		super(opts);
		this.add_sidebar_toggle_listener();
	}

	make() {
		super.make();
		if (this.should_hide_sidebar()) {
			this.hide_sidebar();
		}
	}

	should_hide_sidebar() {
		const route = frappe.get_route();
		const is_list_view = route[0] === "List";
		const is_home_page = route.length === 1 && route[0] === "Home";

		return is_list_view && !is_home_page;
	}

	hide_sidebar() {
		this.sidebar.hide();
		this.collapse_sidebar_space();
	}

	show_sidebar() {
		this.expand_sidebar_space();

		if (this.sidebar.is(":empty")) {
			this.sidebar.html(frappe.render_template("list_sidebar", { doctype: this.doctype }));
			this.setup_list_filter();
			this.setup_list_group_by();

			$(document).trigger("list_sidebar_setup");
		}

		this.sidebar.show();
	}

	collapse_sidebar_space() {
		this.page.sidebar.css({
			width: 0,
			padding: 0,
			display: "none",
		});
	}

	expand_sidebar_space() {
		this.page.sidebar.css({
			width: "",
			padding: "",
			display: "",
		});
	}

	add_sidebar_toggle_listener() {
		const toggle_button = $(".page-head").find(".sidebar-toggle-btn");

		toggle_button.off("click").on("click", () => {
			if (this.sidebar.is(":visible")) {
				this.hide_sidebar();
			} else {
				this.show_sidebar();
			}
		});
	}
};

frappe.views.ListSidebar = frappe.views.CustomListSidebar;
